/* Ajoutez ces styles dans votre fichier style.css */

main {
  padding: 0px !important;
  min-height: 68.6vh;
}


/*=============== HEADER ===============*/
.header {
  padding: 2px !important;
  background-color: #0d0e0f !important;
  /* Couleur de fond */
}

.navbar-brand {
  font-family: 'Arial', sans-serif !important;
  font-weight: 700;
  font-size: 35px;
  display: flex !important;
  align-items: center !important;
  color: #ecf0f1 !important;
  /* Couleur du texte */
}

.logo {
  width: 75px !important;
  /* Ajustez la taille du logo selon vos besoins */
  margin-right: 15px !important;
}

.navbar-nav .nav-link {
  color: #ecf0f1 !important;
  /* Couleur du texte des liens */
  position: relative;
}

.navbar-nav .nav-link:hover .agnline {
  width: 150px;
}

.agnline {
  transition: 0.5s; 
  position: absolute;
  bottom: 0px;
  left: 8px;
  width: -moz-fit-content;
  width: 0px;
  height: 7px;
}

@media (max-width: 768px) {
  .navbar .container {
    display: unset !important;
    margin-top: -20px !important;
  }

  .navbar-brand {
    font-size: 70px;
    margin: auto;
    display: unset !important;
  }

  .logo {
    margin: 0 !important;
  }

  .navbar-brand span {
    display: none !important;
  }

  .logo {
    width: 70px !important;
  }

  .navbar-nav .nav-link:hover .agnline {
    width: calc(100% - 8px);
  }
}

@media (min-width: 992px) {
  .navbar-nav li {
    margin-left: unset !important;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    flex-direction: row;
  }

  .navbar-nav li {
    margin-left: 10px;
  }
}


/*=============== FOOTER ===============*/
.footer {
  background-color: #0d0e0f;
  color: #ecf0f1;
  padding: 30px 0;
  margin-top: auto;
}

.footer h4 {
  color: #e31203;
}

.icon {
  display: flex;
  justify-content: center;
}

.icon a {
  margin: 0 10px;
}

.icon a {
  color: #ecf0f1;
  text-decoration: none;
}

.icon a:hover {
  color: #e31203;
  /* Couleur rouge au survol */
}

/* FOOTER LINKS */

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #ecf0f1;
}

.footer-links a {
  font-size: 12px;
  margin: 0 10px;
  color: #0d0e0f;
  text-decoration: none;
}

/* Styles spécifiques pour les écrans de petite taille */
@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-links a {
    margin: 5px 0;
  }
}

/*=============== GENERAL ===============*/
body {
  font-family: 'Arial', sans-serif !important;
  background-color: #ecf0f1 !important;
  /* Couleur de fond générale */
  color: #0d0e0f !important;
  /* Couleur de texte générale */
}

.container {
  max-width: 1200px !important;
  margin: auto !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.agn-row-center {
  align-items: center !important;
  max-width: 100% !important;
}

h2 {
  font-weight: bold;
}

/*=============== Company Presentation ===============*/
.company-presentation {
  padding: 0px !important;
  transition: transform 0.3s;
}

.company-presentation:hover {
  transform: scale(1.05);
}

.company-presentation .col-md-6 {
  padding: 0px !important;
}

.company-presentation .col-md-6 p {
  padding: 0px 25px !important;
}

@media (max-width: 768px) {
  .company-presentation .col-md-6 {
    background-color: #fff;
    ;
  }

  .company-presentation .col-md-6 h2 {
    margin-top: 15px !important;
  }

  .company-presentation .col-md-6 p {
    padding: 10px !important;
    padding-left: 33px !important;
  }
}

/*=============== CAROUSEL ===============*/
.carousel {
  height: 33vw;
  overflow: hidden;
  /* padding: 0px 50px; */
  background-color: #f2f2f2;
  position: relative;
}

.carousel-item img {
  object-fit: cover;
  width: 80%;
  height: 100%;
  object-position: center;
}

/* Ajoutez ces styles à votre fichier CSS */
.carousel-control-prev,
.carousel-control-next {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 5%;
  padding-left: 2px;
  margin-right: 7%;
  margin-top: auto;
  margin-bottom: auto;
  background-color: #000000d4;
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

.carousel-control-prev {
  padding-right: 4px;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 1;
}

@media (max-width: 768px) {

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .carousel {
    height: fit-content;
    width: 100%;
  }
}

/*=============== MAP ===============*/
.map-container {
  position: relative;
  text-align: center;
  margin-top: 20px;
  /* Ajustez selon vos besoins */
}

.centered-map {
  width: 50%;
  height: 400px;
  border: 1px solid #ccc;
  /* Ajoutez une bordure si nécessaire */
}

.container-bg-dpe {
  position: relative;
}

.background-image {
  background-color: #0d0e0f;
  position: absolute;
  z-index: -1;
  top: 27%;
  width: 100%;
  height: 44%;
}

h2.txt-white {
  color: #ecf0f1;
  margin-bottom: 15px;

}

/*=============== ENGAGEMENT ===============*/
.card {
  transition: transform 0.3s;
}

.h-100 {
  height: 100%;
}

.card:hover {
  transform: scale(1.05);
}

.card:hover .card-title,
.card:hover .card-text {
  color: #e31203;
}

@media (max-width: 1300px) {
  .centered-map {
    width: 75%;
  }
}

@media (max-width: 768px) {
  .background-image {
    top: 25%;
    height: 77%;

  }

  .centered-map {
    width: 95%;
  }

  .container-bg-dpe .container .row {
    margin-top: 2rem !important;
  }

  .container-bg-dpe .card {
    max-width: 70% !important;
    margin: auto !important;
    margin-top: 1rem !important;
  }
}

/*=============== TEAM ===============*/

.teamcontainer {
  max-width: 80% !important;
  padding: 1% 5%;
}

.teamcontainer h2 {
  font-weight: bold
}

.teams-card {
  max-width: 80% !important;
  overflow: hidden;
  text-align: start;
  height: fit-content;
  transition: transform 0.3s;
  box-shadow: 0 0 15px 15px rgba(0, 0, 0, 0.1);
}

.teams-card:hover {
  transform: scale(1.05);
}

.teams-card:hover .team-contact-hover {
  color: #e31203;

}

.card-right {
  margin-left: auto;
  text-align: end;
}

.teams-card .team-info {
  padding: 1rem;
  background-color: #0d0e0f;
  /* Couleur de fond du corps */
  min-height: 250px;
  height: 100%;
  position: relative;
}

.team-title {
  font-weight: 700;
}

.team-role {
  margin-bottom: 2rem !important;
}

.team-description {
  margin-bottom: 1rem !important;
}

.team-contact {
  position: absolute;
  bottom: 10px;
}

.card-right .team-contact {
  right: 1rem;
}

.teams-card .team-image {
  width: 100%;
  height: 100%;
  max-height: 300px;
  object-fit: cover;
}

.teams-card .team-info h5,
.teams-card .team-info p {
  color: #fff;
  margin: 0;
}

/* Supprime la marge entre l'image et le corps */
.teams-card div {
  padding: 0;
}


@media (max-width: 1300px) {
  .teamcontainer {
    max-width: 90% !important;
  }
}

@media (max-width: 768px) {
  .teamcontainer {
    justify-content: space-between !important;
  }

  .teams-card {
    max-width: 100% !important;
    margin: 3rem auto !important;
  }

  .card-right {
    margin-left: unset !important;
    text-align: start !important;
  }

  .team-contact {
    position: unset !important;
    bottom: 10px;
  }

  .card-right .team-contact {
    right: 1rem;
  }
}

/*=============== NOS PRESTATIONS ===============*/
.diagcontainer {
  max-width: 90% !important;
  padding: 1% 5%;
  background-color: #fff;
}

.diagcontainer h2 {
  font-weight: bold;
}

.diagcontainer>p {
  margin: auto;
  margin-bottom: 3rem;
  font-weight: bold;
  width: 50%;
}

.diags-card {
  max-width: 60% !important;
  overflow: hidden;
  align-items: center;
  text-align: start;
  height: fit-content;
  transition: transform 0.3s;
}

.diags-card:hover {
  transform: scale(1.05);
}

.diags-card:hover .diag-contact-hover {
  color: #e31203;
  font-weight: 600;
}

.card-right {
  margin-left: auto;
  text-align: end;
}

.diags-card .diag-info {
  padding: 1rem;
  position: relative;
}

.diag-title {
  text-transform: uppercase;
  position: relative;
  font-weight: 700;
  margin-bottom: 2rem !important;
}

.diag-description {
  margin-bottom: 1rem !important;
}

.diags-card .diag-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.diags-card .diag-info h5,
.diags-card .diag-info p {

  margin: 0;
}

/* Supprime la marge entre l'image et le corps */
.diags-card div {
  padding: 0;
}

.diags-card .diag-image-col-phone {
  display: none;
}

@media (max-width: 1450px) {
  .diags-card {
    max-width: 90% !important;
  }
}

@media (max-width: 1300px) {
  .diagcontainer {
    max-width: 90% !important;
  }

  .diagcontainer>p {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .diagcontainer {
    justify-content: space-between !important;
  }

  .diagcontainer>p {
    width: 85%;
  }

  .diags-card {
    align-items: unset;
    max-width: 100% !important;
    margin: 3rem auto !important;
  }

  .diags-card .diag-image-col {
    display: none !important;
  }

  .diags-card .diag-image-col-phone {
    display: block;
    margin: auto;
    width: 90%;
    overflow: hidden;
  }

  .card-right {
    margin-left: unset !important;
    text-align: start !important;
  }

  .diag-contact {
    position: unset !important;
    bottom: 10px;
  }

  .card-right .diag-contact {
    right: 1rem;
  }
}




/*=============== CONTACT US ===============*/
.contact-us {
  max-width: 950px !important;
}

.contact-us h2 {
  color: #e31203;
  /* Couleur rouge pour le titre */
}

.contact-us>.row>.col-md-6 {
  margin-top: 30px;
  text-align: left;
}

.contact-us .col-md-6 .container {
  margin-top: 30px;
  text-align: center;
}

.contact-us .col-md-6 img {
  object-fit: cover;
  width: 85%;
  height: 239px;
  margin-bottom: 1rem;
}

.contact-us .col-md-6 h4 {
  text-align: center;
}

.contact-us .col-md-6 p {
  margin-inline: 2rem;
  padding: 10px 15px;
  background-color: #FFF;
  color: #c60c02;
  font-weight: 500;
}

.contact-us .col-md-6 p strong {
  color: #0d0e0ff2
}

.contact-us button {
  text-align: right important !;
}

/* CONTACT FORM */
.contact-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.contact-form>div.text-center {
  font-size: large;
  color: #c60c02;
}

.contact-form label {
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 5px;
}

.contact-form textarea {
  height: 162px;
}

.form-row {
  display: flex;
  gap: 10px;
}

.contact-form button {
  background-color: #e31203;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.contact-form button:hover {
  background-color: #c60c02;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: #ccc;
  border-color: #ccc;
  opacity: var(--bs-btn-disabled-opacity);
}

/*=============== RESPONSIVE STYLES ===============*/
@media (max-width: 768px) {
  .contact-form {
    margin-top: 20px;
    /* Ajustez selon vos besoins pour une meilleure disposition sur les petits écrans */
  }
}